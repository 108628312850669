import { ReactNode } from 'react'

export enum ColumnSizeTypes {
  Half = 'half',
  Half_Reversed = 'half-reversed',
  Full = 'full'
}

export type StandardScreenProps = {
  children?: ReactNode
  onboarding?: boolean
  hasSingleColumn?: boolean | (() => boolean)
  columnSize?: () => ColumnSizeTypes | false
  publicScreen?: boolean
  preventFixedHeader?: boolean
}

export const ProfilePageLayoutProps = {
  hasSingleColumn: false,
  publicScreen: true,
  columnSize: () => ColumnSizeTypes.Half,
  preventFixedHeader: true
}
